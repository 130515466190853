@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  color:rgba(189,189,189,1)
}

* {
  box-sizing: border-box;
}

.amzn-native-header-text {
  color: white;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
